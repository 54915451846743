var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-detail d-flex flex-column scroll-container"},[(_vm.list)?_c('v-container',{staticClass:"d-flex flex-column scroll-container"},[_c('v-row',{staticClass:"list-detail-header align-center"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"list-name secondary--text"},[_c('strong',[_vm._v("Lista: ")]),_vm._v(_vm._s(_vm.list.name)+" ("+_vm._s(_vm.list.wishlistItems.length)+" prodotti) ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex justify-end",class:{ 'flex-column': _vm.$vuetify.breakpoint.xs }},[_c('v-tooltip',{staticClass:"mx-1",attrs:{"top":"","open-on-hover":true,"open-on-click":true,"max-width":"500","disabled":_vm.editable},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"bg-white",attrs:{"outlined":"","color":"primary","depressed":"","disabled":_vm.list.wishlistItems.length +
                      _vm.list.wishlistInactiveItems.length ==
                      0 || !_vm.editable},on:{"click":function($event){return _vm.emptyList()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.lists.emptyList")))])])],1)]}}],null,false,1787464632)},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.lists.notEditable")))])]),_c('v-btn',{staticClass:"bg-white mx-0 ml-sm-2 mr-sm-0",attrs:{"outlined":"","color":"primary","depressed":"","disabled":_vm.list.wishlistItems.length + _vm.list.wishlistInactiveItems.length ==
                0},on:{"click":function($event){return _vm.goToGridView(_vm.list)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.lists.switchToGrid")))])]),_c('v-btn',{staticClass:"mx-0 ml-sm-2 mr-sm-0",attrs:{"color":"primary","depressed":"","disabled":_vm.list.wishlistItems.length + _vm.list.wishlistInactiveItems.length ==
                0},on:{"click":_vm.addAllToCart}},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.lists.addAllToCart")))])])],1)])],1),(_vm.list.wishlistItems.length + _vm.list.wishlistInactiveItems.length > 0)?_c('div',{staticClass:"detail-table t-list-detail"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{staticClass:"table-header",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_vm._v(" "+_vm._s(_vm.$t("profile.lists.header.description"))+" ")]),_c('v-col',{staticClass:"table-column justify-left hidden-sm-and-down",attrs:{"cols":"4","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("profile.lists.header.unitPrice"))+" ")]),_c('v-col',{staticClass:"table-column justify-center hidden-sm-and-down",attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"1"}})],1):_vm._e(),(_vm.list)?_c('v-list',{staticClass:"t-detail-list"},[_vm._l((_vm.list.wishlistItems),function(item){return _c('ListItem',{key:item.itemId,attrs:{"item":item,"isActive":true,"editable":_vm.editable},on:{"removeItem":_vm.removeFromList,"update":_vm.updateList}})}),_vm._l((_vm.list.wishlistInactiveItems),function(item){return _c('ListItem',{key:item.itemId,attrs:{"item":item,"editable":_vm.editable,"isActive":false},on:{"removeItem":_vm.removeFromList}})})],2):_vm._e()],1):_vm._e(),(
        _vm.list.wishlistItems.length + _vm.list.wishlistInactiveItems.length == 0
      )?_c('v-card',{staticClass:"mt-3 pa-2 text-center no-lists",staticStyle:{"width":"100%"},attrs:{"light":"","depresses":"","elevation":"0"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("profile.lists.noProduct")))])],1):_vm._e()],1):_vm._e(),_c('v-spacer',{staticClass:"spacer-scroll"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }