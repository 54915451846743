<template>
  <v-list-item v-if="item">
    <v-row no-gutters class="align-center">
      <v-col cols="12" md="5">
        <div class="d-flex flex-column">
          <div class="promo-container d-flex mb-2">
            <div
              class="promo-box text-uppercase product-badge novita mr-2"
              v-if="product.newProduct"
            >
              Novità
            </div>
            <ProductPromo
              :warehousePromo="item.product.warehousePromo"
              v-if="item.product.warehousePromo"
            ></ProductPromo>
          </div>

          <div class="d-flex align-center product-column">
            <v-img
              :src="item.product.mediaURL"
              class="product-img d-xs-none"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
            />
            <div class="product-info">
              <span class="product-code">Cod. {{ productCode }}</span>
              <span class="product-name">{{ item.product.name }}</span>
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
              <div
                class="product-descr"
                v-if="item.product.logisticPackagingItems > 0"
              >
                Imballo: {{ item.product.logisticPackagingItems }} pz
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="4" md="2" class="justify-center justify-sm-left">
        <ProductPrice
          v-if="isActive"
          :product="item.product"
          :showStandard="false"
        ></ProductPrice
      ></v-col>

      <v-col
        :cols="editable ? 7 : 8"
        :md="editable ? 4 : 5"
        class="d-flex justify-center"
      >
        <template v-if="isActive">
          <div
            v-if="!showQntyInput"
            class="qty-wrap rounded-pill"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <v-btn
              role="button"
              class="minus "
              v-on:click.prevent.stop="minus"
              :disabled="!editable"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <div
              class="val-cnt"
              @mousedown.stop
              @click.stop.prevent=""
              @dblclick="toggleShowInput"
              v-long-press="300"
              @long-press-start="toggleShowInput"
            >
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small">{{ quantityPerUnit }}</span>
            </div>
            <v-btn
              role="button"
              class="plus "
              v-on:click.prevent="plus"
              :disabled="!editable"
            >
              <v-icon v-if="quantity > 0">mdi-plus</v-icon>
            </v-btn>
          </div>
          <div
            v-else
            class="qnty-manual-input rounded-md"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <v-btn
              role="button"
              class="close"
              @click.stop.prevent="toggleShowInput(false)"
              @mousedown.stop
              :disabled="!editable"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-tooltip
              bottom
              :open-on-hover="false"
              :open-on-click="true"
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="qntyInput"
                  ref="manualInput"
                  class="manual-input"
                  hide-details
                  autofocus
                  outlined
                  dense
                  v-mask="'###'"
                  type="number"
                  @click.stop.prevent=""
                  @keyup.enter="toggleShowInput(true)"
                  @keyup.esc="toggleShowInput(false)"
                  @mousedown.stop
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span
                class="qntity-tooltip"
                v-if="
                  product.productInfos.QTY_LOCKED != 'true' ||
                    (product.productInfos.QTY_LOCKED == 'true' &&
                      !modulus(qntyInput))
                "
                v-html="
                  $t('product.quantity.confirmQntity', {
                    qntyInput: this.qntyInput
                  })
                "
                @click="clicked"
              >
              </span>
              <span
                class="qntity-tooltip"
                v-if="
                  product.productInfos.QTY_LOCKED == 'true' &&
                    modulus(qntyInput) &&
                    parseInt(qntyInput) <=
                      parseInt(this.product.productInfos.QTY_MULT)
                "
                v-html="
                  $t('product.quantity.editQntityMin', {
                    lowerInput: this.lowerInput
                  })
                "
                @click="clicked"
              >
              </span>

              <span
                class="qntity-tooltip"
                v-if="
                  product.productInfos.QTY_LOCKED == 'true' &&
                    modulus(qntyInput) &&
                    parseInt(qntyInput) >
                      parseInt(this.product.productInfos.QTY_MULT)
                "
                v-html="
                  $t('product.quantity.editQntityMinMax', {
                    lowerInput: this.lowerInput,
                    higherInput: this.higherInput
                  })
                "
                @click="clicked"
              >
              </span>
            </v-tooltip>
            <v-btn
              role="button"
              class="check"
              @click.stop.prevent="toggleShowInput(true)"
              @mousedown.stop
              :disabled="!editable"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </div>
          <a
            role="button"
            class="add-to-cart "
            v-on:click.prevent="
              () => {
                if (item.product.price || item.product.priceDisplay) {
                  addToCart();
                }
              }
            "
            :class="{
              disabled: !item.product.price && !item.product.priceDisplay
            }"
            v-ripple
          >
            <v-icon>$cart</v-icon>
          </a>
        </template>
        <template v-else>
          <span>Prodotto non acquistabile</span>
        </template>
      </v-col>
      <v-col
        cols="1"
        md="1"
        class="d-flex align-center justify-center"
        v-if="editable"
      >
        <v-btn
          small
          icon
          class="ml-1 delete-item-btn"
          v-on:click.prevent="removeItem()"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style scoped lang="scss">
.manual-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.qntity-tooltip {
  color: $primary;
  font-size: 13px;
}
.promo-box {
  margin-top: -10px;
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import LongPress from "vue-directive-long-press";

import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

import CartService from "~/service/cartService";
import CatalogService from "~/service/catalogService";
import { formatProductCode } from "@/service/grosmarketUtils";

import productMixin from "~/mixins/product";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ListItem",
  components: { ProductPrice, ProductPromo },
  mixins: [productMixin, clickHandler],
  props: {
    item: { type: Object, required: true },
    isActive: { type: Boolean },
    editable: { type: Boolean, default: true }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  // mixins: [productMixin],
  data() {
    return {
      internalQuantity: global.config.updateCart == true ? 0 : -1,
      qntyInput: 0,
      showQntyInput: false
    };
  },

  computed: {
    product() {
      return this.item.product;
    },
    productCode() {
      return formatProductCode(this.item.product);
    },
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    approxQtyMultProduct() {
      if (this.item.product.productInfos.QTY_LOCKED == "true") {
        var newApproxValue = Math.round(
          this.qntyInput / this.item.product.productInfos.QTY_MULT
        );
        this.qntyInput =
          Math.max(newApproxValue, 1) * this.item.product.productInfos.QTY_MULT;
      }
      if (
        this.item.product.productInfos.MAXACQ &&
        this.qntyInput > this.item.product.productInfos.MAXACQ
      )
        this.qntyInput = this.item.product.productInfos.MAXACQ;
    },
    async toggleShowInput(save) {
      let vm = this;
      if (
        this.item.product.productInfos.TIPOLOGIA == "Pezzo" ||
        this.item.product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        if (this.showQntyInput) {
          // chiudo input
          this.showQntyInput = false;
          if (save) {
            vm.approxQtyMultProduct();
            vm.internalQuantity = vm.qntyInput;
            if (vm.item && vm.item.quantity != vm.internalQuantity) {
              const newQuantity = parseInt(vm.internalQuantity);
              let res = true;
              if (newQuantity <= 0) {
                res = await this.$dialog.confirm({
                  text: global.vm.$t("message.removeFromWishlist")
                });
              }
              if (res) this.$emit("update", { newQuantity, item: this.item });
            }
          }
        } else {
          // open and init manual input
          if (this.internalQuantity >= 0) {
            // product card and detail
            this.qntyInput = this.internalQuantity;
          } else {
            // checkout
            this.qntyInput = this.item.quantity;
          }
          this.showQntyInput = true;
          console.log(vm);
          // vm.$nextTick(() => {
          //   vm.$refs.manualInput.focus();
          // });
        }
      }
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);
      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);
      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeFromWishlist")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    }
  }
};
</script>
