<template>
  <div class="list-detail d-flex flex-column scroll-container">
    <v-container class="d-flex flex-column scroll-container" v-if="list">
      <v-row class="list-detail-header align-center">
        <v-col cols="12" sm="6" class="d-flex align-center">
          <h3 class="list-name secondary--text">
            <strong>Lista: </strong>{{ list.name }} ({{
              list.wishlistItems.length
            }}
            prodotti)
          </h3>
        </v-col>
        <!-- <v-col cols="12" md="6" class="d-flex flex-column flex-md-row"> -->
        <v-col cols="12" sm="6">
          <div
            class="d-flex justify-end"
            :class="{ 'flex-column': $vuetify.breakpoint.xs }"
          >
            <v-tooltip
              top
              :open-on-hover="true"
              :open-on-click="true"
              max-width="500"
              :disabled="editable"
              class="mx-1"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    outlined
                    color="primary"
                    class="bg-white"
                    depressed
                    :disabled="
                      list.wishlistItems.length +
                        list.wishlistInactiveItems.length ==
                        0 || !editable
                    "
                    @click="emptyList()"
                  >
                    <span>{{ $t("profile.lists.emptyList") }}</span>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("profile.lists.notEditable") }}</span>
            </v-tooltip>

            <v-btn
              outlined
              color="primary"
              class="bg-white mx-0 ml-sm-2 mr-sm-0"
              depressed
              :disabled="
                list.wishlistItems.length + list.wishlistInactiveItems.length ==
                  0
              "
              @click="goToGridView(list)"
            >
              <span>{{ $t("profile.lists.switchToGrid") }}</span>
            </v-btn>

            <v-btn
              color="primary"
              class="mx-0 ml-sm-2 mr-sm-0"
              depressed
              @click="addAllToCart"
              :disabled="
                list.wishlistItems.length + list.wishlistInactiveItems.length ==
                  0
              "
            >
              <span>{{ $t("profile.lists.addAllToCart") }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div
        class="detail-table t-list-detail"
        v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
      >
        <v-row
          no-gutters
          class="table-header"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-col cols="12" md="5">
            {{ $t("profile.lists.header.description") }}
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="table-column justify-left hidden-sm-and-down"
          >
            {{ $t("profile.lists.header.unitPrice") }}
          </v-col>
          <v-col
            cols="3"
            class="table-column justify-center hidden-sm-and-down"
          >
            <!-- <strong>Quantità</strong> -->
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-list class="t-detail-list" v-if="list">
          <ListItem
            v-for="item in list.wishlistItems"
            v-bind:key="item.itemId"
            :item="item"
            :isActive="true"
            :editable="editable"
            @removeItem="removeFromList"
            @update="updateList"
          ></ListItem>
          <!-- :itemId="item.itemId" -->
          <!-- :product="item.product" -->
          <ListItem
            v-for="item in list.wishlistInactiveItems"
            v-bind:key="item.itemId"
            :item="item"
            :editable="editable"
            @removeItem="removeFromList"
            :isActive="false"
          ></ListItem>
          <!-- :product="item.product"
            :itemId="item.itemId" -->
        </v-list>
      </div>
      <v-card
        light
        depresses
        elevation="0"
        v-if="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        class="mt-3 pa-2 text-center no-lists"
        style="width:100%;"
      >
        <v-card-title class="headline">{{
          $t("profile.lists.noProduct")
        }}</v-card-title>
      </v-card>
    </v-container>

    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
import ListItem from "@/components/lists/ListItem.vue";

import { mapActions } from "vuex";

import deliveryReactive from "~/mixins/deliveryReactive";

import ListService from "~/service/listService";

var _this;

export default {
  name: "ListDetail",
  props: {
    listId: { type: Number, required: true }
  },
  data() {
    return {
      list: null
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive],
  computed: {
    editable() {
      return this?.list?.wishlistShare?.grant != "READ";
    }
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    fetchList() {
      ListService.getList(this.listId).then(list => {
        _this.list = list;
      });
    },
    async removeFromList({ itemId, product }) {
      let res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          _this.list,
          itemId,
          product.productId
        );
        _this.list = newList;
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${_this.list.name}?`;

      let res = await _this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        _this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await _this.addProductsFromWishlist(this.listId);
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        this.listId,
        item,
        newQuantity
      );
      _this.list = res;
    },
    reload() {
      this.fetchList();
    },
    goToGridView(list) {
      this.$router.push({
        name: "ListDetailGrid",
        path: `/profile/lists/${list.listId}/grid`,
        params: { list: list }
      });
    }
  },
  created() {
    _this = this;
    this.reload();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
