<template>
  <div class="lists-menu">
    <v-list>
      <h3 class="menu-title mb-3"><strong>LE MIE LISTE</strong></h3>
      <!-- v-bind:to="'/profile/lists/' + list.id" -->
      <div class="my-list-container">
        <v-list-item :to="{ name: 'List', params: { listId: 'preferred' } }">
          <i18n
            :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
            tag="strong"
          />
        </v-list-item>
        <v-list-item
          v-for="(list, index) in listsData"
          :key="list.id"
          :to="{ name: 'List', params: { listId: list.id } }"
          :class="{ zebra: index % 2 == 0 }"
        >
          <v-row
            no-gutters
            align="center"
            :class="{ default: list.isDefault }"
            v-if="!list.isEdit"
          >
            <v-col cols="6" md="7">
              <strong class="name">{{ list.name }}</strong>
            </v-col>
            <v-col
              cols="6"
              md="5"
              class="d-flex justify-end justify-content-end align-center"
            >
              <v-tooltip
                left
                v-if="list.wishlistShare && list.wishlistShare.grant == 'EDIT'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small>
                    $iconUsers
                  </v-icon>
                </template>
                Questa lista è condivisa e modificabile da più utenti
              </v-tooltip>
              <v-tooltip left v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      icon
                      v-on:click.prevent="setDefault(list.id)"
                      class="third-icon-button mr-1"
                      :disabled="!isEditGranted(list)"
                    >
                      <v-icon>mdi-star-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>
                  {{
                    isEditGranted(list)
                      ? "Imposta come lista preferita"
                      : $t("profile.lists.notEditable")
                  }}</span
                >
              </v-tooltip>
              <v-tooltip left v-if="list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent=""
                    class="third-icon-button mr-1"
                  >
                    <v-icon>mdi-star</v-icon>
                  </v-btn>
                </template>
                <span>Questa lista è la tua preferita</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      large
                      icon
                      v-on:click.prevent="setEdit(list)"
                      class="third-icon-button mr-1"
                      :disabled="!isEditGranted(listId)"
                    >
                      <v-icon>$edit</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  isEditGranted(list)
                    ? "Rinomina lista"
                    : $t("profile.lists.notEditable")
                }}</span>
              </v-tooltip>

              <v-tooltip left v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      large
                      icon
                      v-on:click.prevent="removeList(list.id, list.name)"
                      class="third-icon-button"
                      :disabled="!isEditGranted(list)"
                    >
                      <v-icon>$delete</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  isEditGranted(list)
                    ? "Elimina lista"
                    : $t("profile.lists.notEditable")
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters align="center" v-if="list.isEdit">
            <!-- label="Inserisci il nome" -->
            <v-col cols="6" sm="6">
              <v-text-field
                hide-details="auto"
                v-model="list.tempName"
                v-on:click.prevent="() => {}"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2"> </v-col>
            <v-col cols="4" sm="4">
              <v-btn
                class="third-icon-button"
                large
                icon
                v-on:click.prevent="confirmEdit(list)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                class="third-icon-button"
                large
                icon
                v-on:click.prevent="undoEdit(list)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </div>
    </v-list>

    <v-divider class="my-5"></v-divider>

    <v-row class="d-flex flex-column create-list-wrapper" no-gutters>
      <h3 class="menu-title mb-3"><strong>CREA UNA NUOVA LISTA</strong></h3>

      <div class="create-input d-flex align-center">
        <v-text-field
          label="Inserisci il nome"
          hide-details="auto"
          v-model="listName"
        ></v-text-field>
        <v-btn
          medium
          v-ripple="false"
          depressed
          class="primary"
          v-on:click="createList()"
          :disabled="createDisabled"
          >CREA</v-btn
        >
      </div>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import list from "~/mixins/list";

export default {
  name: "ListsMenu",
  mixins: [list],
  // props: ["lists"],
  props: {
    listsData: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    createList() {
      let name = this.listName;
      this.$emit("createlist", { name });
    },
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
      //   list.isEdit = true;
      //   list.tempName = list.name;
      //   this.$emit("remove", listId);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(list) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(list.id);
      }
    },
    setOpenList(listId) {
      console.log(listId);
      this.setOpenedListId(listId);
    }
  },
  created() {
    console.log(this);
  }
  // watch: {
  //   lists() {
  //     console.log(this.lists);
  //   }
  // }
};
</script>
